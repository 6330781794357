import { Typography, Box } from '@mui/material';

import FormBuilder from '../../form-builder';
import { CreateCnpjStepFormProps } from './create-cnpj-step-form.types';

export const CreateCnpjStepTwo: React.FC<CreateCnpjStepFormProps> = ({
    label,
    sectionIndex,
    description,
    stepKey,
    inputs,
    errors,
    touched,
    values,
    setFieldValue,
    getFieldProps,
}) => {

    return (
        <Box style={{ margin: 15 }} id="checkout-open-mei-step-2">
            <Typography color={'#2D2935'} marginTop={sectionIndex !== 0 ? 4 : 2} fontSize={24} fontWeight={600} lineHeight={2} letterSpacing={0.2}>
                {label}
            </Typography>
            <Typography color={`rgba(0, 0, 0, .6)`} fontSize={16} marginBottom={4} lineHeight={2} letterSpacing={0.2}>
                {description}
            </Typography>
            <FormBuilder
                errors={errors}
                touched={touched}
                values={values}
                setFieldValue={setFieldValue}
                inputs={inputs}
                getFieldProps={getFieldProps}
            />
        </Box>
    )
}