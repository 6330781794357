import { AxiosResponse } from "axios";
import { api } from "../http";
import { DefaultCallback, DefaultRes } from "../types";
import { Customer } from "../types/customer.types";
import { FormOnboard } from "../types/form-onboard.types";
import { store } from "../store";

export namespace CustomerService {
    export const createCustomer = async (
        values: FormOnboard,
        callback: DefaultCallback,
    ) => {
        try {
            const res = await api.post<
                DefaultRes<Customer>, 
                AxiosResponse<DefaultRes<Customer>>
            >('/customers', { ...values });
            
            if (res.data.isValid) {
                store.dispatch.onboard.setCustomer(res.data.result);
            }

            return callback(res.data.isValid, res.data.message);
     
        } catch (err: any) {
            return callback(false, 'ERR');
        }
    } 

    export const updateCustomer = async (uuid: string, values: FormOnboard) => {
        const res = await api.patch<
            DefaultRes<Customer>, 
            AxiosResponse<DefaultRes<Customer>>
        >(`/customers/${uuid}`, { ...values }); 

        if (res.data.isValid) {
            console.log('data on update', res.data.result);
            store.dispatch.onboard.setCustomer(res.data.result);
        }
    }
}