import { Box, Button, Dialog, DialogContent, IconButton, Stack, Tooltip, Typography, useTheme, useMediaQuery } from "@mui/material";
import { When } from "react-if";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";

interface Props {
    visible: boolean,
    paymentData: any,
    onHide(): void,
}

export const PixPayModal: React.FC<Props> = ({ visible, paymentData, onHide }) => {
    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog
            open={visible}
            maxWidth={'xl'}
            scroll={'paper'}
        >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography sx={{ ml: 3, mb: 2, mt: 2 }} fontSize={18} fontWeight={400} color={'text.default'}>
                        Realizar pagamento
                    </Typography>
                    <Tooltip title="Fechar popup">
                        <IconButton
                            onClick={onHide}
                            component="span"
                            sx={{ mr: 2, height: 30, width: 30, alignSelf: 'center' }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Box>
            <DialogContent dividers>
                <Stack display="flex" flexDirection="column" justifyContent={"center"} alignItems={'center'} alignContent={"center"}>
                    <When condition={paymentData}>
                        <Typography fontSize={14} mb={2}>
                            1 - Clique no botão abaixo para copiar o código ou leia o QRCode PIX<br /><br />
                            2 - Abra o aplicativo do seu banco ou instituição financeira e entre na opção Pix <br /><br />
                            3 - Na opção Pix Copia e Cola, insira o código copiado ao apertar o botão abaixo "Copiar código" <br /> <br />
                        </Typography>
                        <img src={`data:image/png;base64, ${paymentData?.encodedImage}`} />
                        <Button variant="contained" fullWidth={isSmallScreen} sx={{ mt: 2 }}
                            onClick={async () => {
                                await navigator.clipboard.writeText(paymentData?.payload);
                                toast(
                                    'Código pix copiado com sucesso!',
                                    {
                                    autoClose: 1500,
                                    type: 'success',
                                    },
                                );
                            }}
                        >
                            Copiar código
                        </Button>
                    </When>
                </Stack>
            </DialogContent>
        </Dialog>
    )
};