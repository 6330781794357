import { createModel } from "@rematch/core";

import type { RootModel } from '.';
import { FormOnboard } from "../types/form-onboard.types";
import { Customer } from "../types/customer.types";

export interface OnboardStoreProps {
    form: FormOnboard,
    actualStep: number,
    customer: Customer,
    done: boolean,
};

export const onboard = createModel<RootModel>()({
    state: {
        form: {
            secondaryOcupations: [],
            personalAddressIsSameComercial: 'true',
        } as FormOnboard,
        customer: {} as Customer,
        actualStep: 0,
        done: false,
    },
    reducers: {
        setDone: (store, v: boolean) => {
            store.done = v;
        },
        setForm: (store, form: any) => {

            let newForm = form;

            if (!form.secondaryOcupations) {
                newForm.secondaryOcupations = [];
            }

            if (!form.personalAddressIsSameComercial) {
                newForm.personalAddressIsSameComercial = 'true';
            }

            store.form = newForm;
        },
        setActualStep: (store, ac: number) => {
            store.actualStep = ac
        },
        setCustomer: (store, c: Customer) => {
            store.customer = c;
        }
    }
});