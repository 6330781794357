import { Typography, Box, RadioGroup, FormControlLabel, Radio, useTheme, useMediaQuery } from '@mui/material';
import { When } from 'react-if';
import FormBuilder from '../../form-builder';
import { CreateCnpjStepFormProps } from './create-cnpj-step-form.types';

export const CreateCnpjStepThree: React.FC<CreateCnpjStepFormProps> = ({
    label,
    sectionIndex,
    stepKey,
    inputs,
    errors,
    touched,
    values,
    setFieldValue,
    getFieldProps,
}) => {
    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box id="checkout-open-mei-step-3" style={{ margin: 15, marginBottom: isSmallScreen ? 50 : 0 }}>

            <When condition={stepKey === 'personalAddress'}>
                <Typography color={'#2D2935'} marginBottom={4} marginTop={sectionIndex !== 0 ? 4 : 2} fontSize={24} fontWeight={600} lineHeight={2} letterSpacing={0.2}>
                    {label}
                </Typography>
                <FormBuilder
                    errors={errors}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    inputs={inputs}
                    getFieldProps={getFieldProps}
                />
                <Typography mt={4} fontSize={14} mb={1} fontWeight={600} color="#2D2935">
                    Seu endereço pessoal é o mesmo que o comercial? *
                </Typography>
                <RadioGroup onChange={(e, v) => setFieldValue('personalAddressIsSameComercial', (v))} style={{ flexDirection: 'row' }} value={values?.personalAddressIsSameComercial} defaultValue={true}>
                    <FormControlLabel value={true} control={<Radio />} label="Sim" />
                    <FormControlLabel value={false} control={<Radio />} label="Não" />
                </RadioGroup>
            </When>

            <When condition={stepKey === 'comercialAddress' && values?.personalAddressIsSameComercial === 'false'}>
                <Typography color={'#2D2935'} marginBottom={4} marginTop={sectionIndex !== 0 ? 4 : 2} fontSize={24} fontWeight={600} lineHeight={2} letterSpacing={0.2}>
                    {label}
                </Typography>
                <FormBuilder
                    errors={errors}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    inputs={inputs}
                    getFieldProps={getFieldProps}
                />
            </When>
            
        </Box>
    )
}