import { Navigate, useRoutes } from 'react-router-dom';

import LogoOnlyLayout from '../layout/only-logo-layout';
import Index from '../pages/app/index';
// import_screen

const AppRoutes = () => { 
  const AVAILABLE_ROUTES = {
    path: '/',
    element: <LogoOnlyLayout />,
    children: [
      { element: <Navigate to="/" replace /> },
      { path: '', element: <Index /> }, 
    ]
  };

  return useRoutes([AVAILABLE_ROUTES ]);
};

export default AppRoutes;